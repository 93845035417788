import { createModel } from '@rematch/core';

import { Store } from '../';

import {
	Admin,
	IAccount,
	StatsOutput,
	UserCreateInput,
	UserReadOutput,
	UsersReadInput,
	UsersReadOutput,
	RequestsReadInput,
	RequestsReadOutput,
	ResponseFail,
	TogglePaidStatusInput,
	RequestReadOutput0,
	PayoutAthlete,
	PayoutsOutput,
	PayoutsInput,
	UserUpdateInput,
	isCompleted,
	CustomPush,
	CustomPushInput,
	CustomPushSend,
	ReadSentPayoutsOutput,
	ReadSentPayoutsInput,
	PromoCodes,
	PromoCode,
	SyncUserInput
} from '~/services';

export type PayoutAthletePriced = PayoutAthlete & {
	amount: number
}

interface AdminState {
	stats: StatsOutput | null,
	users: UsersReadOutput,
	usersById: Record<string, UserReadOutput>,
	requestsById: Record<string, RequestReadOutput0>,
	requestsByUserId: Record<string, RequestsReadOutput>,
	payouts: PayoutAthlete[],
	pushes: CustomPush[],
	sentPayouts: ReadSentPayoutsOutput,
	promoCodes: PromoCode.ReadOutput,
}

interface PromoCodeToggleStateProps {
	code: PromoCode.PromoCode,
	key: 'archived' | 'enabled',
}

const state: AdminState = {
	stats: null,
	users: {
		page: 0,
		users: [],
		total_pages: 0,
	},
	usersById: {},
	requestsById: {},
	requestsByUserId: {},
	payouts: [],
	pushes: [],
	sentPayouts: {
		page: 0,
		total_pages: 0,
		payouts_list: [],
	},
	promoCodes: {
		page: 0,
		total_pages: 0,
		promo_code: [],
	},
};

export const admin = createModel<Store.General>()({
	state,
	reducers: {
		_insertUsers: (state, users: UsersReadOutput) => {
			return {
				...state,
				users,
			};
		},
		_insertUser: (state, payload: { id: string, user: UserReadOutput }) => {

			state.usersById[payload.id] = payload.user;

			return state;

		},
		_insertStats: (state, stats: StatsOutput) => {
			return {
				...state,
				stats,
			};
		},
		_insertRequest: (state, payload: { id: string, request: RequestReadOutput0 }) => {

			state.requestsById[payload.id] = payload.request;

			return state;

		},
		_insertRequests: (state, payload: { id: string, requests: RequestsReadOutput }) => {

			state.requestsByUserId[payload.id] = payload.requests;

			return state;

		},
		_createUser: (state, user: IAccount) => {
			return {
				...state,
				users: {
					...state.users,
					users: [ { ...user, pending_payouts: 0 }, ...state.users.users ],
				},
			};
		},
		_toggleUserBlock: (state, id: string) => {

			if (state.usersById[id]) {
				state.usersById[id] = {
					...state.usersById[id],
					account: {
						...state.usersById[id].account,
						blocked: !state.usersById[id].account.blocked,
					},
				};
			}

			state.users.users = state.users.users.map(
				(user) => user.id === id ? { ...user, blocked: !user.blocked } : user,
			);

			return state;

		},
		_removeUser: (state, id: string) => {

			if (state.usersById[id]) {
				delete state.usersById[id];
			}

			state.users.users = state.users.users.filter(
				(user) => user.id !== id
			);

			return state;

		},
		_togglePaidStatus: (state, payload: TogglePaidStatusInput) => {

			const { id, user_id, amount, status } = payload;

			const _id = payload.campaign_id || id;

			if (state.requestsById[_id]) {
				state.requestsById[_id].requests = state.requestsById[_id].requests.map(
					(request) => request.id !== id ? request : ({
						...request,
						payment: !request.payment ? null : {
							...request.payment,
							payout_status: status,
						},
					}),
				);
			}

			if (state.usersById[user_id]) {
				state.usersById[user_id].amount = state.usersById[user_id].amount - amount;
			}

			if (state.requestsByUserId[user_id]) {
				state.requestsByUserId[user_id].requests = state.requestsByUserId[user_id].requests.map(
					(request) => request.id !== id ? request : ({
						...request,
						payment: !request.payment ? null : {
							...request.payment,
							payout_status: status,
						},
					}),
				);
			}

			return state;

		},
		_clear: () => {
			return { ...state };
		},
		INSERT_PAYOUTS: (state, { athletes_for_payout: payouts }: PayoutsOutput) => {
			return {
				...state,
				payouts,
			};
		},
		USER_UPDATE: (state, payload: UserUpdateInput) => {

			const profile_completed = isCompleted(payload);

			return {
				...state,
				users: {
					...state.users,
					users: state.users.users.map(
						(user) => user.id !== payload.id ? user : {
							...user,
							profile_completed,
							first_name: payload.first_name,
							last_name: payload.last_name,
							profile_photo: payload.profile_photo,
						},
					),
				},
				usersById: {
					...state.usersById,
					[payload.id]: {
						...state.usersById[payload.id],
						account: {
							...state.usersById[payload.id].account,
							...payload,
							profile_completed,
						},
					},
				},
			};

		},
		CREATE_PUSH: (state, push: CustomPush) => {

			return {
				...state,
				pushes: [ push, ...state.pushes ],
			};

		},
		INSERT_PUSHES: (state, pushes: CustomPush[]) => {

			return {
				...state,
				pushes,
			};

		},
		PUSH_UPDATE: (state, push: CustomPush) => {

			return {
				...state,
				pushes: state.pushes.map(
					(i) => i.id === push.id ? push : i
				),
			};

		},
		DELETE_PUSH: (state, id: string) => {

			return {
				...state,
				pushes: state.pushes.filter(
					(i) => i.id !== id
				),
			};

		},
		INSERT_SENT_PAYOUTS: (state, sentPayouts: ReadSentPayoutsOutput) => {
			return {
				...state,
				sentPayouts,
			};
		},
		INSERT_PROMO_CODES: (state, promoCodes: PromoCode.ReadOutput) => {
			return {
				...state,
				promoCodes,
			};
		},
		CREATE_PROMO_CODE: (state, payload: PromoCode.CreateOutput) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: [ payload.promo_code, ...state.promoCodes.promo_code ],
				},
			};
		},
		UPDATE_PROMO_CODE: (state, { promo_code }: PromoCode.UpdateOutput) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: state.promoCodes.promo_code.map(
						(item) => item.id === promo_code.id ? promo_code : item
					),
				},
			};
		},
		DELETE_PROMO_CODE: (state, { id }: PromoCode.DeleteInput) => {
			return {
				...state,
				promoCodes: {
					...state.promoCodes,
					promo_code: state.promoCodes.promo_code.filter(
						(item) => item.id !== id
					),
				},
			};
		},
	},
	effects: (dispatch) => ({
		readUsers: async (params: UsersReadInput) => {

			const { page, users, total_pages } = await Admin.readUsers(params).promise;

			const output = {
				page,
				users,
				total_pages
			};

			dispatch.admin._insertUsers(output);

			return output;

		},
		readUser: async (user_id: string, state) => {

			if (state.admin.usersById[user_id]) {
				return;
			}

			try {

				const { amount, account, requests } = await Admin.readUser({ user_id }).promise;

				dispatch.admin._insertUser({
					id: user_id,
					user: {
						amount,
						account,
						requests,
					},
				});

				return true;

			} catch (e) {

				return null;

			}

		},
		readRequests: async (params: RequestsReadInput, state) => {

			const { amount, page, requests, total_pages } = await Admin.readRequests(params).promise;

			dispatch.admin._insertRequests({
				id: params.user_id,
				requests: {
					page,
					amount,
					requests,
					total_pages
				},
			});

		},
		readStats: async () => {

			const { counters } = await Admin.readStats().promise;

			dispatch.admin._insertStats(counters);

		},
		readRequest: async (id: string, state): Promise<RequestReadOutput0 | null> => {

			if (state.admin.requestsById[id]) {
				return state.admin.requestsById[id];
			}

			try {

				const request = await Admin.readRequest({ id }).promise;

				delete (request as any).status_response;

				dispatch.admin._insertRequest({ id, request });

				return request;

			} catch (e) {

				return null;

			}

		},
		createUser: async (params: UserCreateInput) => {

			try {

				const { user } = await Admin.createUser(params).promise;

				dispatch.admin._createUser(user);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		toggleUserBlock: async (user_id: string) => {

			try {

				await Admin.blockUser({ user_id }).promise;

				dispatch.admin._toggleUserBlock(user_id);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		removeUser: async (user_id: string) => {

			try {

				await Admin.removeUser({ user_id }).promise;

				dispatch.admin._removeUser(user_id);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		togglePaidStatus: async (params: TogglePaidStatusInput) => {

			try {

				await Admin.togglePaidStatus(params).promise;

				dispatch.admin._togglePaidStatus(params);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPayouts: async () => {

			try {

				const payload = await Admin.getPayouts().promise;

				dispatch.admin.INSERT_PAYOUTS(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		sendPayouts: async (data: PayoutAthletePriced[]) => {

			try {

				const newData: PayoutsInput[] = data.map((athlete) => ({
					amount: athlete.amount,
					email: athlete.email,
					phone: athlete.mobile,
					athlete_id: athlete.id,
				}));

				await Admin.sendPayouts(newData).promise;

				await dispatch.admin.readPayouts();

				await dispatch.admin.readSentPayouts({});

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updateUser: async (data: UserUpdateInput) => {

			try {

				await Admin.userUpdate(data).promise;

				dispatch.admin.USER_UPDATE(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createPush: async (data: CustomPushInput) => {

			try {

				const { custom_push } = await Admin.Pushes.create(data).promise;

				dispatch.admin.CREATE_PUSH(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPushes: async (request?: string) => {

			try {

				const { custom_push } = await Admin.Pushes.read(request).promise;

				dispatch.admin.INSERT_PUSHES(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updatePush: async (data: CustomPushInput & { id: string }) => {

			try {

				const { custom_push } = await Admin.Pushes.update(data.id, data).promise;

				dispatch.admin.PUSH_UPDATE(custom_push);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		deletePush: async (id: string) => {

			try {

				await Admin.Pushes.delete(id).promise;

				dispatch.admin.DELETE_PUSH(id);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		sendPush: async (data: CustomPushSend) => {

			try {

				await Admin.Pushes.send(data).promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readSentPayouts: async (data: ReadSentPayoutsInput) => {

			try {

				const sentPayouts = await Admin.readSentPayouts(data).promise;

				dispatch.admin.INSERT_SENT_PAYOUTS(sentPayouts);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		readPromoCodes: async (input: PromoCode.ReadInput) => {

			try {

				const promoCodes = await PromoCodes.read(input).promise;

				dispatch.admin.INSERT_PROMO_CODES(promoCodes);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		createPromoCode: async (data: PromoCode.CreateInput) => {

			try {

				const payload = await PromoCodes.create(data).promise;

				dispatch.admin.CREATE_PROMO_CODE(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		updatePromoCode: async (data: PromoCode.UpdateInput) => {

			try {

				const payload = await PromoCodes.update(data).promise;

				dispatch.admin.UPDATE_PROMO_CODE(payload);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		deletePromoCode: async (data: PromoCode.DeleteInput) => {

			try {

				await PromoCodes.delete(data).promise;

				dispatch.admin.DELETE_PROMO_CODE(data);

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		togglePromoCodeState: async (props: PromoCodeToggleStateProps) => {

			const { code, key } = props;

			try {

				const { promo_code } = await PromoCodes.update({
					...code,
					[key]: !code[key],
				 }).promise;

				dispatch.admin.UPDATE_PROMO_CODE({ promo_code });

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		syncUser: async (props: SyncUserInput) => {

			try {

				await Admin.syncUser(props).promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
		syncUsers: async () => {

			try {

				await Admin.syncUsers().promise;

				return true;

			} catch (e) {

				return (e as ResponseFail).message;

			}

		},
	}),
});
