import { useForm } from 'react-hook-form';

import { lang } from '~/constants';
import { RequestForm, requestSocialPlatform } from '~/services';
import { usePromoCode } from '~/hooks';

import {
	Input,
	Button,
	Legend,
	BigAmount,
	PromoCode,
	RSelect,
	RequestPriceInput
} from '~/components';

export const Endorsement: React.FC<RequestForm.Props> = (props) => {

	const { athlete, onSubmit, processes } = props;

	const { control, handleSubmit, reset } = useForm<RequestForm.Endorsement>();

	const {
		code,
		error,
		setCode,
		discount,
		resetCode,
		isCalculating
	 } = usePromoCode({
		amount: athlete?.athlete_info.athlete_services.endorsement_rate
	});

	const submit = (form: RequestForm.Endorsement) => {

		onSubmit({ ...form, promo_code: code }, () => reset());

	}

	if (!athlete) {
		return null;
	}

	return (
		<>
			<p children={lang.REQUEST_ENDORSEMENT_TITLE} />
			<Input
				name="task"
				icon="description2"
				rules={{ required: true }}
				control={control}
				textarea
				placeholder="Endorsement task"
				maxLength={500} />
			<Legend
				label={lang.REQUEST_INFO_LABEL}
				children={
					<div className="legend-section">
						<Input
							name="name"
							note={lang.REQUEST_NAME_NOTE}
							rules={{ required: true }}
							control={control}
							placeholder={lang.REQUEST_NAME_PLACEHOLDER} />
						<RSelect
							name="social_media_platform"
							note={lang.REQUEST_PLATFORM_NOTE}
							isMulti
							control={control}
							options={requestSocialPlatform}
							placeholder={lang.REQUEST_PLATFORM_PLACEHOLDER}
							defaultValue={null} />
					</div>
				} />
			<PromoCode
				code={code}
				error={error}
				setCode={setCode}
				resetCode={resetCode}
				isCalculating={isCalculating} />
			{/* <Legend
				label="Total price"
				children={(
					<BigAmount
						amount={athlete.athlete_info.athlete_services.endorsement_rate}
						discount={discount} />
				)} /> */}
			<RequestPriceInput
				name="request_amount"
				price={athlete.athlete_info.athlete_services.endorsement_rate}
				control={control}
				discount={discount} />
			<Button
				label="Checkout"
				loading={processes || isCalculating}
				variant="primary"
				onClick={handleSubmit(submit)}
				disabled={!!error}
				disabledDeep />
		</>
	);

}
