import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryParams, NumberParam, withDefault, StringParam } from 'use-query-params';
import { Link, useHistory } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { toast } from 'react-toastify';
import { validate } from 'uuid';
import cn from 'classnames';

import { useRequest, useUsers } from '~/store';
import { ModalUserCreate, ModalUserEdit } from '~/containers/manage';

import {
	Avatar,
	Button,
	Loader,
	Pages,
	Search,
	Select,
	useOverlay,
	UserLabel
} from '~/components';

import {
	confirm,
	ProtectedFC,
	UserCreateInput,
	UserRolesArray,
	UsersReadInput,
	UserType,
	_confirm
} from '~/services';

export const Users: ProtectedFC = () => {

	const wrapRef = useRef<HTMLDivElement>(null);

	const { push } = useHistory();

	const [ query, setQuery ] = useQueryParams({
		page: withDefault(NumberParam, 0),
		request: withDefault(StringParam, ''),
		userType: NumberParam,
		completeness: withDefault(NumberParam, 2),
	});

	const [ pageSize ] = useLocalStorage('usersPageSize', '20');

	const [ params, setParams ] = useState<UsersReadInput>({
		page: query.page,
		request: query.request,
		page_size: + pageSize,
		user_type: query.userType as UserType,
	});

	useEffect(() => {

		wrapRef?.current?.scrollTo({ top: 0, left: 0 });

		setParams((val) => ({
			...val,
			page: (
				val.request !== query.request ||
				val.user_type !== query.userType
			) ? 0 : query.page,
			request: query.request,
			user_type: query.userType as UserType,
			profile_completed: query.completeness as 0 | 1 | 2
		}));

	}, [ query ]);

	const { users, createUser, removeUser, toggleUserBlock, loading, syncUser, syncUsers } = useUsers(params);

	const { readRequest } = useRequest();

	const [ isUpdated, setIsUpdated ] = useState(false);

	const { Over, OverItem, ...over } = useOverlay({
		create: {
			title: 'Create',
			className: 'create',
		},
		edit: {
			className: 'edit--modal',
		},
	}, async (key) => {

		if (key !== 'edit' || !isUpdated) {
			return true;
		}

		const confirmed = await _confirm.editLeave();

		if (confirmed) {
			setIsUpdated(false);
		}

		return confirmed;

	});

	const create = useCallback(
		async (form: UserCreateInput) => {

			const resp = await createUser(form);

			if (typeof resp === 'string') {
				return toast.error(resp);
			}

			toast.info('User has been created');

			over.hide('create');

		},
		[ over, createUser ]
	);

	const remove = useCallback(
		async (id: string, e: React.MouseEvent) => {

			e.preventDefault();

			if (!await confirm({
				title: 'Irreversible Action',
				message: 'Are you sure want to remove the user?',
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await removeUser(id);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: 'Removing the user',
					success: 'The user has been removed',
					error: { render: ({ data }) => data },
				},
			);

		},
		[ removeUser ]
	);

	const toggleBlock = useCallback(
		async (id: string, state: boolean, e: React.MouseEvent) => {

			e.preventDefault();

			if (!await confirm({
				title: 'Irreversible Action',
				message: `Are you sure want to ${state ? 'unb' : 'b'}lock the user?`,
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await toggleUserBlock(id);

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: `${state ? 'Un' : 'B'}locking the user`,
					success: `The user has been ${state ? 'un' : 'b'}locked`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ toggleUserBlock ]
	);

	const onSearch = useCallback(
		async (request: string) => {

			if (!validate(request)) {

				setParams((val) => ({
					...val,
					page: 0,
				}));

				setQuery({
					page: undefined,
					request: request.length ? request : undefined,
				});

				return;

			}

			const resp = await readRequest(request);

			if (!resp) {
				return confirm({
					title: 'Nothing found',
					message: 'Please, confirm the ID and try again',
					buttons: [{
						label: 'OK',
						onClick: () => true,
					}]
				});
			}

			if (!await confirm({
				title: `Found ${resp.type}`,
				message: `Would you like to see the ${resp.type}`,
			})) {
				return;
			}

			push(`/manage/request/${request}`);

		},
		[ readRequest, setQuery, push ]
	);

	const onUserSync = useCallback(
		(user_id: string, e: React.MouseEvent) => {

			e.preventDefault();

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await syncUser({ user_id });

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: `Syncing the user`,
					success: `The user has been synced`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ syncUser ]
	);

	const onSyncAll = useCallback(
		async () => {

			if (!await confirm({
				title: 'Are you sure want to sync all users?',
				message: `The process may take some time`,
			})) {
				return;
			}

			toast.promise(
				new Promise(async (resolve, reject) => {

					const message = await syncUsers();

					if (typeof message === 'string') {
						reject(message);
					}

					resolve(true);

				}),
				{
					pending: `Syncing users`,
					success: `All users have been synced`,
					error: { render: ({ data }) => data },
				},
			);

		},
		[ syncUsers ]
	);

	return (
		<div ref={wrapRef} className={cn('manage-content', { 'manage_overlay': loading.syncUsers })}>
			<div className="page-header">
				<h1>Users</h1>
				<div className="page-controls">
					<Button
						variant="primary"
						label="Add"
						onClick={() => over.show('create')} />
					<Button
						label="Sync all"
						onClick={onSyncAll} />
					<Search
						loading={loading.readUsers || loading.readRequest}
						onSearch={onSearch}
						placeholder="Search for users"
						defaultValue={params.request} />
				</div>
			</div>
			<div className="page-middle">
				<Select
					compact
					options={[
						{ label: 'Any type', value: -1 },
						...UserRolesArray
					]}
					onChange={({ value }) => {
						setQuery({
							page: undefined,
							userType: value as number | undefined,
						});
					}}
					value={params.user_type}
					defaultValue={params.user_type} />
				<Select
					compact
					options={[
						{ label: 'Any status', value: 2 },
						{ label: 'Only completed', value: 1 },
						{ label: 'Only incomplete', value: 0 },
					]}
					onChange={({ value }) => {
						setQuery({
							page: undefined,
							completeness: value as 0 | 1 | 2 | undefined,
						});
					}}
					value={params.profile_completed}
					defaultValue={params.profile_completed} />
					<Loader
						loading={loading.readUsers} />
			</div>
			<div className="users-list">
				{users.users.map((user, i) => (
				<Link
					to={`/manage/user/${user.id}${user.user_type === 2 ? '?payoutStatus=0' : ''}`}
					key={i}
					className="users-list--item">
					<Avatar
						src={user.profile_photo}
						alt={`${user.first_name} ${user.last_name}`}
						count={user.pending_payouts}
						countTitle="Pending payouts count" />
					<div className="user-content">
						<h4>
							{(user.first_name || user.last_name) && <span>{user.first_name}{user.last_name ? ` ${user.last_name}` : ''}</span>}
							{!user.profile_completed && <div className="user-role">Not finished</div>}
							<UserLabel role={user.user_type} />
						</h4>
						<p>{user.email}</p>
					</div>
					<div className="user-actions">
						{user.user_type === 2 && (
						<Button
							label="Edit"
							onClick={(e) => {

								e.preventDefault();

								over.show('edit', user.id);

							}} />
						)}
						<Button
							label={`${user.blocked ? 'Unb' : 'B'}lock`}
							onClick={(e) => toggleBlock(user.id, user.blocked, e)} />
						<Button
							label="Sync"
							onClick={(e) => onUserSync(user.id, e)}
							disabled={loading.syncUser} />
						<Button
							label="Remove"
							onClick={(e) => remove(user.id, e)} />
					</div>
				</Link>
				))}
			</div>
			{!!(users.users.length || params.page) && (
			<Pages
				forcePage={params.page}
				pageCount={users.total_pages}
				onPageChange={({ selected: page }) => {
					setQuery({ page: page === 0 ? undefined : page });
				}} />
			)}
			<Over {...over}>
				<OverItem
					{...over}
					name="create"
					render={() => (
						<ModalUserCreate
							loading={loading.createUser}
							onHide={() => over.hide('create')}
							onCreate={create} />
					)} />
				<OverItem
					{...over}
					name="edit"
					render={(id) => {
						if (!id) {
							return;
						}
						return (
							<ModalUserEdit
								athleteId={id as string}
								onClose={() => over.hide('edit')}
								updated={[ isUpdated, setIsUpdated ]} />
						);
					}} />
			</Over>
			<div className="manage-overlay">
				<div className="content">
					<Loader loading />
					<h2>Syncing users</h2>
					<h6>Please wait and do not refresh or close the page</h6>
				</div>
			</div>
		</div>
	);

}
