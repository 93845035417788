import { api } from '~/services';
import { DeepWritableObject as Writable } from '~/types';

export namespace PromoCode {
	export type DiscountType = 'flat' | 'percent';
	export interface PromoCode {
		readonly id: string,
		readonly created_at: number,
		readonly updated_at: number,
		readonly usage_count: number,
		readonly total_discount_amount: number,
		code: string,
		enabled: boolean,
		archived: boolean,
		usage_limit: number,
		discount_type: DiscountType,
		discount_amount: number,
		expiration_time: number,
		usage_per_user_limit: number,
		total_discount_amount_limit: number,
	}
	export interface CreateInput extends Writable<PromoCode> {}
	export interface ReadInput {
		page?: number,
		archived?: boolean,
	}
	export interface UpdateInput extends CreateInput {
		id: string,
	}
	export interface DeleteInput {
		id: string,
	}
	export interface CreateOutput {
		promo_code: PromoCode,
	}
	export interface ReadOutput {
		page: number,
		promo_code: PromoCode[],
		total_pages: number,
	}
	export interface UpdateOutput extends CreateOutput {}
}

const path = `/admin/promo-codes` as const;

export const discountTypeOptions = [
	{ label: 'Flat', value: 'flat' },
	{ label: 'Percent', value: 'percent' },
];

export const PromoCodes = {

	create: (body: PromoCode.CreateInput) => api.post<PromoCode.CreateOutput>(path, body),

	read: (query: PromoCode.ReadInput) => api.get<PromoCode.ReadOutput>(`${path}/list`, query),

	update: (body: PromoCode.UpdateInput) => api.put<PromoCode.UpdateOutput>(path, body),

	delete: (body: PromoCode.DeleteInput) => api.del(path, body),

};
