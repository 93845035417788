import cn from 'classnames';
import { Controller } from 'react-hook-form';

import { getState, ControlledField, HTMLInput } from '~/types';

import { IconArgs } from './Icon';
import { ButtonProps } from './Button';
import { SquareIcon } from './SquareIcon';

export interface InputProps extends HTMLInput {
	icon?: IconArgs | null,
	note?: string,
	error?: string,
	loading?: boolean,
	textarea?: boolean,
	innerRef?: React.LegacyRef<HTMLInputElement>,
	maxLength?: number,
	iconButton?: ButtonProps
}

type Props = ControlledField<InputProps>

const InputInner: React.FC<Props['inner']> = (props) => {

	const {
		icon,
		note,
		error,
		loading,
		textarea,
		innerRef,
		maxLength,
		iconButton,
		...input
	} = props;

	const state = getState(input);

	return (
		<div className={cn('app-input', { invalid: !!state?.error || !!error })}>
			{icon && (
			<SquareIcon
				icon={icon}
				type="button"
				loading={loading}
				disabled={loading}
				{...iconButton} />
			)}
			<div
				className="app-input--container"
				style={{ marginLeft: icon === null ? 65 : 0 }}>
				{textarea ? (
				<textarea
					ref={innerRef as any}
					className="app-input--element"
					{...input as any} />
				) : (
				<input
					ref={innerRef}
					className="app-input--element"
					{...input} />
				)}
				{!!state?.error?.message && (
				<p
					className="app-input--message"
					children={state.error.message} />
				)}
				{!!note &&
				<p className="app-input--message note" children={note} />
				}
				{!!error &&
				<p className="app-input--message" children={error} />
				}
			</div>
		</div>
	);

}

export const Input: React.FC<Props['outer']> = (props) => {

	if (!('control' in props)) {
		return <InputInner {...props} />
	}

	const {
		name = '',
		rules = {},
		control,
		defaultValue = '',
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field, fieldState }) => {

				const { ref, value, onBlur, onChange } = field;

				return (
					<InputInner
						{...props}
						value={value}
						state={fieldState}
						onBlur={onBlur}
						innerRef={ref}
						onChange={onChange} />
				);

			}} />
	);

}
