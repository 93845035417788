import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import cn from 'classnames';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { usePayouts } from '~/store';
import { handleError, _confirm } from '~/services';
import { Button, Check, Loader, Pages } from '~/components';
import { PayoutAthletePriced } from '~/store/models/Admin';

const _isInArray = <T extends { id: string }>(arr: T[], item: T) => {

	const _isFound = !!arr.filter(({ id }) => id === item.id)[0];

	return _isFound;

}

const _pushOrRemove = <T extends { id: string }>(arr: T[], item: T) => {

	if (_isInArray(arr, item)) {
		return arr.filter(({ id }) => id !== item.id);
	}

	return [ item, ...arr ];

}

const PER_PAGE = 20;

const Td: React.FC<{
	value: number | string,
	asDate?: boolean | string,
}> = (props) => {

	const { value, asDate } = props;

	return (
		<td className={cn({ empty: !value })}>
			{!!value ? !!asDate ? dayjs(+ value * 1000).format(typeof asDate === 'string' ? asDate : 'MM/DD/YYYY') : value : '-'}
		</td>
	);

}

const AmountInput: React.FC<{
	value: number,
	onValueChange: (val: number) => void
}> = (props) => {

	const { value, onValueChange } = props;

	const [ _value, _setValue ] = useState<string | undefined>(`${value}`);

	return (
		<div className="amount">
			<CurrencyInput
				prefix="$"
				value={_value}
				onBlur={() => onValueChange(+ (_value || 0))}
				defaultValue={value}
				onValueChange={(val) => {
					_setValue(val);
					onValueChange(+ (val || 0));
				}}
				allowNegativeValue={false} />
		</div>
	);

}

export const Payouts: React.FC = () => {

	const { payouts, sendPayouts } = usePayouts();

	const wrapRef = useRef<HTMLDivElement>(null);

	const [ paying, setPaying ] = useState(false);

	const [ page, setPage ] = useQueryParam<number>('page', {
		decode: (val) => val && !isNaN(+ val) ? + val : 0,
		encode: (val) => val.toString(),
	});

	useEffect(() => {

		wrapRef.current?.scrollTo(0, 0);

	}, [ page ]);

	const pageCount = useMemo(
		() => {
			return Math.ceil(payouts.length / PER_PAGE);
		},
		[ payouts ]
	);

	const data = useMemo(
		() => {

			const offset = page * PER_PAGE;

			return payouts.slice(offset, offset + PER_PAGE);

		},
		[ page, payouts ]
	);

	const [ athletes, setAthletes ] = useState<PayoutAthletePriced[]>([]);

	const totalPayout = useMemo(
		() => {
			return athletes.reduce((i, { amount }) => i + (amount > 0 ? amount : 0), 0);
		},
		[ athletes ]
	);

	const pay = useCallback(
		async () => {

			if (!await _confirm.payoutsSend(totalPayout, athletes.length)) {
				return;
			}

			try {

				setPaying(true);

				const resp = await sendPayouts(athletes);

				if (typeof resp === 'string') {

					setPaying(false);

					return toast.error(resp);

				}

				setAthletes([]);

				setPaying(false);

				toast.success('Payout sent');

			} catch (e) {

				setPaying(false);

				handleError(e);

			}

		},
		[ totalPayout, athletes, sendPayouts ]
	);

	const isPageSelected = useMemo(
		() => {

			const selectedIds = athletes.map(({ id }) => id);

			if (!selectedIds.length) {
				return false;
			}

			const selectedIdsFromPage = data.filter(
				(i) => selectedIds.includes(i.id)
			);

			return selectedIdsFromPage.length === data.length;

		},
		[ data, athletes ]
	);

	const togglePage = useCallback(
		() => {

			if (isPageSelected) {

				const removeIds = data.map(({ id }) => id);

				setAthletes(
					athletes.filter((i) => !removeIds.includes(i.id))
				);

				return;

			}

			const newData = data.map((payout) => ({
				...payout,
				amount: payout.past_due + payout.current_due,
			}));

			setAthletes(() => ([
				...athletes,
				...newData.filter((i) => !_isInArray(athletes, i)),
			]));

		},
		[ data, athletes, isPageSelected ]
	);

	const updateValue = useCallback(
		(id: string, amount: number) => {

			setAthletes(
				athletes.map((i) => i.id !== id ? i : { ...i, amount })
			);

		},
		[ athletes ],
	);

	return (
		<div ref={wrapRef} className={cn('manage-content payouts--page', { 'manage_overlay': paying })}>
			<div className="page-header">
				<h1>Payouts</h1>
				<Link
					to="/manage/sent-payouts"
					children="Go to sent payouts" />
			</div>
			<div className="page-body">
				<table>
					<thead>
						<tr>
							<td>
								<Check
									onClick={togglePage}
									checked={isPageSelected} />
							</td>
							<td>Name</td>
							<td style={{ textAlign: 'right' }}>Mobile</td>
							<td>Email</td>
							<td>Last Payment Date</td>
							<td style={{ textAlign: 'center' }}>Last Payment Amount</td>
							<td>Past Due</td>
							<td>Current Due</td>
							<td>Total Due</td>
							<td>Payout</td>
						</tr>
					</thead>
					<tbody>
						{data.map((payout) => {
							const isSelected = _isInArray(athletes, payout);
							const totalDue = payout.past_due + payout.current_due > 0 ?
								payout.past_due + payout.current_due :
								0;
							return (
								<tr key={payout.id}>
									<td>
										<Check
											checked={isSelected}
											onClick={() => setAthletes(_pushOrRemove(athletes, { ...payout, amount: totalDue }))} />
									</td>
									<td>{payout.first_name} {payout.last_name}</td>
									<td style={{ textAlign: 'right' }}>{payout.mobile}</td>
									<td>{payout.email}</td>
									<Td
										value={payout.last_payment_date}
										asDate />
									<td style={{ textAlign: 'center' }}>${payout.last_payment_amount}</td>
									<td>${payout.past_due}</td>
									<td>${payout.current_due}</td>
									<td>${payout.past_due + payout.current_due}</td>
									<td>
										{isSelected ?
											<AmountInput
												value={totalDue}
												onValueChange={(amount) => updateValue(payout.id, amount)} /> :
											<div className="amount-blank" />
										}
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={9}></td>
							<td>${totalPayout}</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div className="page-footer">
				<Pages
					forcePage={page}
					pageCount={pageCount}
					initialPage={page}
					onPageChange={({ selected }) => setPage(selected)} />
				<Button
					label="Pay"
					variant="primary"
					onClick={pay}
					loading={paying}
					disabled={totalPayout <= 0}
					disabledDeep />
			</div>
			<div className="manage-overlay">
				<div className="content">
					<Loader loading />
					<h2>Sending payout</h2>
					<h6>Please wait</h6>
				</div>
			</div>
		</div>
	);

}
