import { Link, NavLink } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAuth } from '~/store';
import { Button, Icon } from '~/components';

export const FrameTenants: React.FC = (props) => {

	const { children } = props;

	const { loading, unAuthorize } = useAuth();

	return (
		<>
			<aside className="manage-aside manage-aside--tenants">
				<Link to="/tenants">
					<Icon name="logo" />
				</Link>
				<nav>
					<NavLink to="/tenants" exact>
						<Icon name="menuTenants" />
						<span>Tenants</span>
					</NavLink>
					{/* <NavLink to="/tenants/settings">
						<Icon name="menuSettings" />
						<span>Settings</span>
					</NavLink>
					<NavLink to="/tenants/analytics">
						<Icon name="menuAnalytics" />
						<span>Analytics</span>
					</NavLink> */}
				</nav>
				<div className="logout">
					<Button
						label="Logout"
						loading={loading.unAuthorize}
						onClick={() => unAuthorize()} />
				</div>
			</aside>
			<main className="manage-content--tenants" children={children} />
			<ToastContainer
				theme="dark"
				position="bottom-right" />
		</>
	);

}
