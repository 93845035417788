import { Control } from 'react-hook-form';

import { config } from '~/constants';
import { Discount, RequestForm } from '~/services';

import { BigAmount } from './BigAmount';
import { InputPrice } from './InputPrice';
import { Legend, LegendProps } from './Legend';

const {
	MIN,
	MAX,
	LABEL,
	PLACEHOLDER,
	ERROR_REQUIRED,
	ERROR_INVALID,
} = config.ENDORSEMENT_CONFIG;

type Union = RequestForm.Endorsement | RequestForm.EndorsementCampaign
type ControlWrap<U> = U extends infer T ? T extends any ? Control<U> : never : never;

export interface RequestPriceInputProps<T> extends Omit<LegendProps, 'label'> {
	price: number,
	name?: string,
	note?: (price: number) => React.ReactNode,
	label?: string,
	control?: ControlWrap<T>,
	discount?: Discount,
	placeholder?: string,
}

export const RequestPriceInput = <T extends Union>(props: RequestPriceInputProps<T>) => {

	const {
		price,
		name,
		note = (price) => <><span>${price}</span> is a recommended price, but you can click on the price to specify a different amount</>,
		label = LABEL,
		control,
		discount,
		placeholder = PLACEHOLDER,
	} = props;

	const children = control ?
		<InputPrice
			name={name}
			rules={{
				required: ERROR_REQUIRED,
				validate: (val) => {
					if (!Number(val)) {
						return ERROR_INVALID;
					}
					if (Number(val) < MIN || Number(val) >= MAX) {
						return `Please, enter amount between ${MIN} and ${MAX}`;
					}
					return true;
				}
			}}
			control={control}
			placeholder={placeholder}
			defaultValue={price} /> :
		<BigAmount
			amount={price}
			discount={discount} />

	return (
		<div className="app-legend app-input--legend">
			<Legend
				label={label}
				children={children} />
			{(!!note && price && control) &&
			<p
				children={note(price)}
				className="app-input--legend-note" />
			}
		</div>
	);

}
