import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { PromoCode } from '~/services';
import { Legend, OptionView } from '~/components';
import { toTitleCase } from '~/utils';

dayjs.extend(localizedFormat);

export interface ModalPromoCodeProps {
	promoCode: PromoCode.PromoCode,
	onHide: () => void,
}

export const ModalPromoCode: React.FC<ModalPromoCodeProps> = (props) => {

	const {
		promoCode: {
			code,
			discount_type,
			discount_amount,
			created_at,
			updated_at,
			expiration_time,
			usage_count,
			usage_limit,
			usage_per_user_limit,
			total_discount_amount,
			total_discount_amount_limit,
			...promoCode
		},
	} = props;

	const hasLimits =
		!!usage_limit ||
		!!usage_per_user_limit ||
		!!total_discount_amount ||
		!!total_discount_amount_limit;

	return (
		<>
			<Legend
				label="Promo Code">
				<OptionView
					label="Code"
					value={code} />
				<OptionView
					label="Discount Type"
					value={toTitleCase(discount_type)} />
				<OptionView
					label="Discount Amount"
					value={discount_type === 'percent' ? `${discount_amount * 100}%` : `$${discount_amount.toFixed(2)}`} />
				{!!usage_count &&
				<OptionView
					label="Used"
					value={`${usage_count} time${usage_count > 1 ? 's' : ''}`} />
				}
				<OptionView
					label="Created At"
					value={dayjs(created_at * 1000).format('LLL')} />
				<OptionView
					label="Updated At"
					value={dayjs(updated_at * 1000).format('LLL')} />
				{expiration_time > 0 &&
				<OptionView
					label="Expires At"
					value={dayjs(expiration_time * 1000).format('LLL')} />
				}
			</Legend>
			{hasLimits &&
			<Legend
				label="Limits">
				{!!total_discount_amount &&
				<OptionView
					label="Total discount limit"
					value={`$${total_discount_amount.toFixed(2)}`} />
				}
				{!!total_discount_amount_limit &&
				<OptionView
					label="Total discount amount limit"
					value={`$${total_discount_amount_limit.toFixed(2)}`} />
				}
				{!!usage_limit &&
				<OptionView
					label="Usage limit"
					value={`${usage_limit} time${usage_limit > 1 ? 's' : ''}`} />
				}
				{!!usage_per_user_limit &&
				<OptionView
					label="Usage limit per user"
					value={`${usage_per_user_limit} time${usage_per_user_limit > 1 ? 's' : ''}`} />
				}
			</Legend>
			}
		</>
	);

}
