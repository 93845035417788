import cn from 'classnames';
import { asIcon, Icon, IconArgs } from './Icon';

import { HTMLButton } from '~/types';

interface SquareIconProps extends HTMLButton {
	icon: IconArgs,
	link?: string,
	loading?: boolean,
	disabled?: boolean
}

export const SquareIcon: React.FC<SquareIconProps> = (props) => {

	const { icon, link, loading, ...button } = props;

	const className = cn('app-square-icon', {
		clickable: !!button.onClick
	});

	if (link) {
		return (
			<a className={className} href={link}>
				<Icon {...asIcon(icon)} />
			</a>
		);
	}

	return (
		<button className={className} {...button}>
			<Icon {...asIcon(icon)} />
		</button>
	);

}
