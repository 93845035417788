import cn from 'classnames';

import { daysLeft, mediaLink } from '~/utils';
import { RequestStatus, RequestType, REQUEST_TYPE, REQUEST_STATUS, statusMap } from '~/services';

import { ButtonProps } from './Button';
import { SquareIcon } from './SquareIcon';
import { IconParam } from './IconParam';
import { Icon } from './Icon';

export type RequestParamItem = {
	type: 'amount',
	value: number
} | {
	type: 'type',
	value: RequestStatus
} | {
	type: 'requestor',
	value: string
} | {
	type: 'expireTs',
	value: number
}

interface RequestCardProps {
	type: RequestType,
	title: string,
	photo?: string,
	status: RequestStatus,
	params: RequestParamItem[],
	button?: ButtonProps,
	onClick?: (e: React.MouseEvent) => void
}

export const RequestCard: React.FC<RequestCardProps> = (props) => {

	const { type, title, photo, status, params, onClick } = props;

	const className = cn(
		'app--request-card',
		REQUEST_STATUS[status][1]
			.toLowerCase()
			.replace('_', '-')
	);

	return (
		<div
			onClick={onClick}
			className={className}>
			<div className="app--request-card-inner">
				{photo ? (
				<div
					style={{ backgroundImage: `url(${mediaLink(photo, 'S')})` }}
					className="request-photo" />
				) : (
				<SquareIcon
					icon={REQUEST_TYPE[type][2]} />
				)}
				<div className="request-content">
					<h4>{title}</h4>
					<div className="request-params">
						{params.map(({ type, value }, i) => {
							switch (type) {
								case 'type':
									return <IconParam key={i} icon={{ name: REQUEST_TYPE[props.type][2] }} text={'rtest'} />
								case 'requestor':
									return <IconParam key={i} icon={{ name: 'user' }} text={value as string} />
								case 'amount':
									return null;
								case 'expireTs':
									return <IconParam key={i} icon={{ name: 'calendar' }} text={status === 1 ? `${daysLeft(value as number)} left to accept` : REQUEST_STATUS[status][0]} />
							}
							return null;
						})}
					</div>
				</div>
				<div className="request-icon">
					<Icon name={statusMap[status].icon} />
				</div>
				<div className="request-arrow">
					<Icon
						fill="#4a4a4a"
						name="arrowNext" />
				</div>
			</div>
		</div>
	);

}
