import { useCallback, useState } from 'react';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { Loader } from '~/components';
import { config } from '~/constants';
import { useTenants } from '~/store';

export const Tenants: React.FC = () => {

	const [ loading, setLoading ] = useState(false);

	const { tenants, signUp } = useTenants();

	const tenantSignUp = useCallback(
		async ([ id, url ]: [ id: string, url: string ]) => {

			setLoading(true);

			const session = await signUp(id);

			if (!session) {
				toast.error('Unable to authorize');
				return void setLoading(false);
			}

			setLoading(false);

			window.location.href = `${url}/?s=${session}`;

		},
		[ signUp ]
	);

	return (
		<div className={cn('manage-content', { 'manage_overlay': loading })}>
			<div className="page-header">
				<h1>Tenants</h1>
			</div>
			<div className="page-body">
				<table>
					<thead>
						<tr>
							<td className="logo"></td>
							<td className="name">Name</td>
							<td>Users</td>
							<td>Total Requests</td>
							<td>Paid Requests</td>
							<td>Pending Requests</td>
						</tr>
					</thead>
					<tbody>
						{tenants.map(({ id, logo, name, stats, web_url }) => (
						<tr key={id} onClick={tenantSignUp.bind(null, [ id, web_url ])}>
							<td className="logo">
								<div className="logo-wrap">
									<img src={config.URL_BASE + logo} alt={name} />
								</div>
							</td>
							<td className="name">{name}</td>
							<td>{stats.users_count}</td>
							<td>{stats.requests_count}</td>
							<td>{`$${stats.paid_requests_amount}`}</td>
							<td>{`$${stats.pending_requests_amount}`}</td>
						</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="manage-overlay">
				<div className="content">
					<Loader loading />
					<h2>Authorizing...</h2>
					<h6>Please wait</h6>
				</div>
			</div>
		</div>
	);

}
