export const Settings: React.FC = () => {

	return (
		<div className="manage-content">
			<div className="page-header">
				<h1>Settings</h1>
			</div>
		</div>
	);

}
